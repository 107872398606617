import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";

import "./Contact.scss";
import DocumentMeta from "react-document-meta";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const { name, email, subject, message } = formData;

  const handleChangeInput = (e) => {
    const { name: fieldName, value } = e.target;

    setFormData((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  const meta = {
    title: 'Beimnet Zewdu - Portfolio Website',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const contact = {
      _type: "contact",
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
    };



    client.create(contact).then(() => {
      setIsLoading(false);
      setIsFormSubmitted(true);
    });
  };

  return (
    <>
       <DocumentMeta {...meta} />
      <h2 className="head-text">
        <span>Feel Free</span> to <span>Connect</span> with <span>Me</span>
      </h2>

      <div className="app__contact-cards">
        <div className="app__contact-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:beimnetzish@gmail.com" className="p-text">E-mail</a>
        </div>
        <div className="app__contact-card ">
          <img src={images.github} alt="github" />
          <a href="https://github.com/beimnetzewdu" className="p-text">GitHUb</a>
        </div>
        <div className="app__contact-card">
          <img src={images.linkedin} alt="linkedin" />
          <a href="https://www.linkedin.com/in/beimnet-zewdu-kebede" className="p-text">Linkedin</a>
        </div>
        <div className="app__contact-card">
          <img src={images.xtwitter} alt="xtwitter" />
          <a href="https://twitter.com/beimnetzewduk" className="p-text">Twitter</a>
        </div>
        <div className="app__contact-card">
          <img src={images.instagram} alt="instagram" />
          <a href="https://www.instagram.com/beimnetzewdu/" className="p-text">Instagram</a>
        </div>
      </div>
      <p1> or don't hesitate to shoot me your message directly 😊</p1>
      {!isFormSubmitted ? (
        <div className="app__contact-form app__flex">
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Your Name"
              value={name}
              onChange={handleChangeInput}
              name="name"
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              className="p-text"
              placeholder="Your Email"
              value={email}
              onChange={handleChangeInput}
              name="email"
            />
          </div>
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Subject"
              value={subject}
              onChange={handleChangeInput}
              name="subject"
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={handleChangeInput}
            />
          </div>
          <button
            type="button"
            className=" portfolio-button"
            onClick={handleSubmit}
          >
            {loading ? "Sending Message" : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h4 className="head-text">
          <span>Thank you</span> for getting in <span>Touch!</span>
          </h4>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Contact, "app__contact"),
  "contact",
  "app__whitebg"
);
