import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
//import ReactTooltip from "react-tooltip";
import DocumentMeta from "react-document-meta";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client, urlFor } from "../../client";
import "./Skills.scss";

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState();

  const skillVariants = {
    view: {
      x: [-15, 0],
      opacity: [0, 1],
      transition: {
        x: {
          type: "spring",
          stiffness: "10",
        },
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    hover: {
      y: -7,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    tap: {
      y: -7,
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  useEffect(() => {
    const skillsQuery = '*[_type=="skills"] | order(proficiency desc)';
    const experienceQuery = '*[_type=="experiences"] | order(year desc)';
    client.fetch(skillsQuery).then((skillsData) => {
      setSkills(skillsData);
    });
    client.fetch(experienceQuery).then((experienceData) => {
      setExperience(experienceData);
    });
  }, []);

  const meta = {
    title: 'Beimnet Zewdu - Portfolio Website',
    description: 'This is the portfolio website, of Beimnet Zewdu a mobile and web applications developer in Addis Ababa, Ethiopia.',
    canonical: 'https://beimnetzewdu.com',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Beimnet, Beimnet Zewdu, Beimnet Zewdu Kebede, Mobile Developer, Web Developer, Website Developer, Website Design, Android Developer, Android App Developer, Mobile Development, Android Development, Android App Development, Best Mobile Developer, Best Mobile App Developer, Best Android App Developer, Best Web Developer,  Best Mobile Developer in Ethiopia, Best Mobile Developer in Addis, Best Mobile Developer in Addis Ababa, Best Mobile Developer in Addis Abeba, Best Web Developer in Ethiopia, Best Web Developer in Addis Ababa, Best Web Developer in Addis Abeba, Best Developer in Ethiopia, Best Developer in Addis Ababa, Beautiful Website, React Developer'
        }
    }
  };

  return (
    <>
     <DocumentMeta {...meta} />
      <h2 className="head-text">
      <span>Skills</span> & <span>Languages</span>
      </h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, index) => (
            <motion.div
              variants={skillVariants}
              whileInView="view"
              whileHover="hover"
              whileTap="tap"
              className="app__skills-item app__flex"
              key={skill.name + "-" + index}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: "#fff"}}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text" id="skill_text" style={{ color: skill.bgColor }}>{skill.name}</p>

            </motion.div>
          ))}
        </motion.div>
        {/*
          <motion.div className="app__skills-exp">
          {experience?.map((exp) => (
            <motion.div className="app__skills-exp-item" key={exp.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{exp.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {exp?.works?.map((work) => (
                  <React.Fragment key={work.name}>
                    <motion.div
                      whileInView={{
                        opacity: [0, 1],
                        x: [-100, 5, 0],
                      }}
                      transition={{
                        duration: 1,
                      }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#1b2598"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </React.Fragment>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
          */}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
