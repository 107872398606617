import React from "react";

import { BsLinkedin, BsInstagram, BsTwitter } from "react-icons/bs";
import { FaMediumM, FaBlog, FaCamera, FaCertificate } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.linkedin.com/in/beimnet-zewdu-kebede"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a
          href="https://medium.com/@beimnetzewdu"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <FaMediumM />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/beimnetzewdu/"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsInstagram />
        </a>
      </div>
      <div>
        <a
          href="https://twitter.com/beimnetzewduk"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsTwitter />
        </a>
      </div>
      <div>
        <a
          href="http://myblog.beimnetzewdu.com/"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <FaBlog />
        </a>
      </div>

      <div>
        <a
          href="https://photography.beimnetzewdu.com/"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <FaCamera />
        </a>
      </div>


      <div>
        <a
          href="https://www.credly.com/users/beimnetzewdukebede/badges"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <FaCertificate />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
